import logo_reversed from './rolec_connect_logo.svg';
import app_store from './app_store.svg';
import google_play from './google_play.png';

const images = {
    logo_reversed,
    app_store,
    google_play
};

export default images;